import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import Faqs from "../components/Faqs";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Banners from "../components/Banners";
import { getData } from "../helper/helper";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  locale,
  title,
  welcome,
  where,
  faqs,
  faqTitle,
  banners,
  ourservices,
}) => {
  const indexPage = getData(locale).indexPage;
  return (
    <>
      <Banners gridItems={banners.banner} />
      <div className="welcome">
        <div className="container">
          <h1 dangerouslySetInnerHTML={{ __html: welcome.description }} />
        </div>
      </div>
      <div className="ourservices">
        <div className="container">
          <h5>{ourservices.heading}</h5>
          <Services
            gridItems={ourservices.ourservice}
            filter=""
            locale={locale}
          />
        </div>
      </div>

      {/* <BackgroundImage
        Tag="section"
        fluid={where.image.childImageSharp.fluid}
        className="where"
      >
        <div className="container">
          <div className="columns">
            <Address gridItems={where} locale={locale} />
          </div>
        </div>
      </BackgroundImage> */}

      <div className="faq">
        <div className="container">
          <h5>{faqTitle}</h5>
          <div className="content">
            <Faqs gridItems={faqs.faq} filter="all" items="10" />
            <Link to={indexPage.faqUrl}>{indexPage.faqButton}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  locale: PropTypes.string,
  welcome: PropTypes.object,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  faqTitle: PropTypes.string,
  where: PropTypes.object,
  banners: PropTypes.shape({
    banner: PropTypes.array,
  }),
  ourservices: PropTypes.shape({
    ourservice: PropTypes.array,
  }),
  faqs: PropTypes.shape({
    faq: PropTypes.array,
  }),
};

const IndexPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.homePageData.edges[0];
  const { node: posts } = props.data.FaqPageData.edges[0];
  return (
    <Layout
      locale={locale}
      title={data.frontmatter.title}
      description={data.frontmatter.description}
    >
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta name="description" content={`${data.frontmatter.description}`} />
      </Helmet>
      <IndexPageTemplate
        image={data.frontmatter.image}
        locale={data.frontmatter.locale}
        title={data.frontmatter.title}
        welcome={data.frontmatter.welcome}
        heading={data.frontmatter.heading}
        subheading={data.frontmatter.subheading}
        description={data.frontmatter.description}
        where={data.frontmatter.where}
        banners={data.frontmatter.banners}
        ourservices={data.frontmatter.ourservices}
        faqs={posts.frontmatter.faqs}
        faqTitle={posts.frontmatter.title}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($locale: String) {
    homePageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "index-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            locale
            title
            where {
              image {
                childImageSharp {
                  fluid(maxHeight: 720, quality: 75) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              title
              adres
              adresId
              tel
              telId
              mail
              mailId
            }
            welcome {
              description
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            heading
            subheading
            description
            banners {
              banner {
                image {
                  childImageSharp {
                    fluid(maxHeight: 680, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                subtitle
                title
                content
                button
                url
                light
              }
            }
            ourservices {
              heading
              ourservice {
                image {
                  childImageSharp {
                    fluid(maxHeight: 500, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                header
                link
              }
            }
          }
        }
      }
    }

    TeamPageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "team-info" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            description
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    FaqPageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "faq-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            description
            faqs {
              faq {
                category
                question
                answer
              }
            }
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
