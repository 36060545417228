import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerGrid = ({ gridItems }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
  };

  return (
    <div className="new-carousel">
      <Slider {...settings}>
        {gridItems &&
          gridItems.map((item) => (
            <div
              key={item.image.childImageSharp.fluid}
              className={"hero " + (item.light ? "" : "dark")}
            >
              <BackgroundImage
                Tag="section"
                fluid={item.image.childImageSharp.fluid}
                style={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="container">
                  <h3>{item.subtitle}</h3>
                  <h2>{item.title}</h2>
                  <p>{item.content}</p>
                  <Link to={`/${item.url}`}>{item.button}</Link>
                </div>
              </BackgroundImage>
            </div>
          ))}
      </Slider>
    </div>
  );
};

BannerGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      subtitle: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      url: PropTypes.string,
      button: PropTypes.string,
    })
  ),
};

export default BannerGrid;
